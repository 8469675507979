/*LIGHTMODE*/
#language-select-light {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    background-color: transparent;
    color: #000;
}

#language-select-light .ReactFlagsSelect-module_selectOptions__3LNBJ {
    border: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    background: #5193B3;
}

#language-select-light .ReactFlagsSelect-module_selectBtn__19wW7 {
    border: 0px;
}

/*DARKMODE*/
#language-select-dark {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    background-color: transparent;
    color: #fff;
}

#language-select-dark .ReactFlagsSelect-module_selectOptions__3LNBJ {
    border: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    background: #212226;
}

#language-select-dark .ReactFlagsSelect-module_selectBtn__19wW7 {
    border: 0px;
}